const state = {
  tab: 0,
  date: {
    startDate: null,
    endDate: null,
  },
  vehicle: [],
  driver: [],
  selected: [],
};

const mutations = {
  setTab(state, val) {
    state.tab = val;
  },
  setDate(state, val) {
    state.date = val;
  },
  setVehicle(state, val) {
    state.vehicle = val;
  },
  setDriver(state, val) {
    state.driver = val;
  },
  setSelected(state, val) {
    state.selected = val;
    // console.log("selected", state.selected);
  },
  removeSelected(state, val) {
    state.selected = state.selected.filter((item) => item !== val);
    // console.log("test item remove", state.selected);
  },
  addSelected(state, val) {
    state.selected.push(val);
    // console.log("test item add", state.selected);
  },
};

const actions = {
  updateTab({ commit }, val) {
    commit("setTab", val);
  },

  updateDate({ commit }, val) {
    commit("setDate", val);
  },
  updateVehicle({ commit }, val) {
    commit("setVehicle", val);
  },
  updateDriver({ commit }, val) {
    commit("setDriver", val);
  },
  updateSelected({ commit }, val) {
    commit("setSelected", val);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
