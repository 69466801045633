import { render, staticRenderFns } from "./DataTableNonVS.vue?vue&type=template&id=86d8b046&scoped=true"
import script from "./DataTableNonVS.vue?vue&type=script&lang=js"
export * from "./DataTableNonVS.vue?vue&type=script&lang=js"
import style0 from "./DataTableNonVS.vue?vue&type=style&index=0&id=86d8b046&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86d8b046",
  null
  
)

export default component.exports